import _ from "lodash";
import { useMemo } from "react";
import type { ComboBoxParentProps } from "~/components/combobox";
import ComboBox from "~/components/combobox";
import type { AreasQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

export default function AreaPicker({
  placeholder = "Select an Area...",
  ...rest
}: ComboBoxParentProps) {
  const fetcher = useFetcherData<AreasQuery>("/resources/areas");

  const options = useMemo(() => {
    const all = _.sortBy(fetcher.data?.areas || [], "name");
    return all.map((o) => ({
      value: o.id,
      label: o.name,
      extra: o
    }));
  }, [fetcher.data]);

  return <ComboBox {...rest} placeholder={placeholder} options={options} />;
}
