import clsx from "clsx";
import _ from "lodash";
import React, { useMemo } from "react";
import { useLoaderData, useLocation } from "react-router";
import { Editor, Element, Transforms } from "slate";
import { ReactEditor, useFocused, useSlate } from "slate-react";
import { Icon } from "~/components/icons";
import type {
  CustomElement,
  MyEditor,
  SlateMarkTypes
} from "~/components/rich-editor";
import { PFCSPhotoButton } from "~/components/rich-editor/images";
import { LinkButton } from "~/components/rich-editor/links";
import {
  ListIndentButton,
  ListSettingsButton
} from "~/components/rich-editor/lists";
import { useOptionalProject } from "~/contexts";
import type { EditorTemplatesQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";
import { slateIsBlank } from "~/utils/text";

export const isFormatActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as SlateMarkTypes] === true : false;
};

const FormatButton = ({
  format,
  icon,
  text
}: {
  format: string;
  icon: string;
  text?: string;
}) => {
  const editor = useSlate();

  return (
    <span
      className={clsx("mr-2", editor.selection && "cursor-pointer")}
      onMouseDown={(event) => {
        event.preventDefault();
        if (isFormatActive(editor, format)) {
          editor.removeMark(format);
        } else {
          editor.addMark(format, true);
        }
      }}
    >
      <Icon
        name={icon}
        fixed
        className={isFormatActive(editor, format) ? "text-primary" : ""}
      />{" "}
      {text}
    </span>
  );
};

export const isBlockActive = (editor: Editor, type: string) => {
  const [match] = Editor.nodes(editor, {
    match: (node) => Element.isElement(node) && node.type === type
  });
  return !!match;
};

const BlockButton = ({
  type,
  icon,
  text
}: {
  type: string;
  icon?: string;
  text?: string;
}) => {
  const editor = useSlate() as MyEditor;
  return (
    <span
      className={clsx(
        isBlockActive(editor, type) && "text-primary",
        editor.selection && "cursor-pointer",
        "mr-2",
        icon ? "font-normal" : "font-bold"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        editor.selection && editor.toggleBlock(type);
      }}
    >
      {icon ? (
        <>
          <Icon name={icon} fixed />{" "}
          <span
            className={editor.selection ? "cursor-pointer" : "cursor-default"}
          >
            {text}
          </span>
        </>
      ) : (
        <span
          className={editor.selection ? "cursor-pointer" : "cursor-default"}
        >
          {text}
        </span>
      )}
    </span>
  );
};
type ToolbarProps = {
  editor: MyEditor;
  panel?: boolean;
  onTemplateChange?: (template: string) => void;
};

export default React.memo(function SlateToolbar({
  panel,
  editor,
  onTemplateChange
}: ToolbarProps) {
  const project = useOptionalProject();
  const focused = useFocused();
  const location = useLocation();
  const loader = useLoaderData<{
    editorTemplatesPicker?: EditorTemplatesQuery;
  } | null>();
  const alreadyFetched = !!loader?.editorTemplatesPicker;
  const fetcher = useFetcherData<EditorTemplatesQuery>(
    alreadyFetched
      ? undefined
      : `/resources/templates/editor-templates?projectId=${project?.id || ""}`
  );

  const insertTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      ReactEditor.focus(editor);
      const match = (
        loader?.editorTemplatesPicker?.templates ||
        fetcher.data?.templates ||
        []
      ).find((t) => t.id === e.target.value);
      if (match) {
        if (slateIsBlank(editor.children)) {
          Transforms.removeNodes(editor, { at: [0] });
        }
        Transforms.insertNodes(
          editor,
          JSON.parse(match.contentSlate!) as CustomElement[]
        );
        onTemplateChange?.(match.name!);
      }
    }
  };
  const templates = useMemo(() => {
    const items =
      loader?.editorTemplatesPicker?.templates || fetcher.data?.templates || [];
    const path = location.pathname.split("/")[1];
    return items.filter(
      (i) =>
        !i.sections.length || i.sections.some((s) => s.toLowerCase() === path)
    );
  }, [
    fetcher.data?.templates,
    loader?.editorTemplatesPicker?.templates,
    location.pathname
  ]);

  return (
    <div
      className={clsx(
        "sticky top-0 z-10 flex flex-wrap items-center rounded-tl-lg rounded-tr-lg border-b py-[6px] transition-all",
        focused ? "border-gray-300 bg-gray-50" : "border-gray-300 bg-white",
        panel ? "px-6" : "px-4"
      )}
    >
      <div
        className={clsx(
          !focused && "opacity-40",
          "flex flex-wrap gap-y-2 transition-opacity duration-75"
        )}
      >
        <BlockButton type="paragraph" text="Normal" />
        <BlockButton type="h1" text="H1" />
        <BlockButton type="h2" text="H2" />
        <BlockButton type="h3" text="H3" />
        <BlockButton type="h4" text="H4" />
        <BlockButton type="h5" text="H5" />
        <span className="inline-block w-2" />
        <FormatButton format="bold" icon="bold" />
        <FormatButton format="italic" icon="italic" />
        <FormatButton format="underline" icon="underline" />
        <span className="inline-block w-2" />
        <LinkButton />
        <PFCSPhotoButton />
        <span className="inline-block w-2" />
        <BlockButton type="ordered-list" icon="numberedList" />
        <BlockButton type="unordered-list" icon="list" />
        <span>
          <ListIndentButton mode="outdent" />
          <ListIndentButton mode="indent" />
          <ListSettingsButton />
        </span>
        <span className="inline-block w-2" />

        <BlockButton type="page-break" icon="page" text="Page Break" />
        <FormatButton format="highlight" icon="flag" text="Flag" />
        <select
          value=""
          onChange={insertTemplate}
          className={clsx(
            "-mb-px mt-[-2px] w-[100px] cursor-pointer px-0 py-[2px]",
            focused ? "bg-gray-50" : "bg-white"
          )}
        >
          <option value="">Template...</option>
          {_.sortBy(templates, "name").map((t) => (
            <option value={t.id} key={t.id}>
              {t.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});
