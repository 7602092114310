import clsx from "clsx";
import { useState } from "react";
import Button from "~/components/button";
import InputGroup from "~/components/input-group";
import AddressCard from "~/components/physical-addresses/card";
import PhysicalAddressForm from "~/components/physical-addresses/form";
import type {
  PhysicalAddressFieldsFragment,
  ResidenceFieldsFragment
} from "~/types/api";

export interface PhysicalAddressPickerProps {
  value?: PhysicalAddressFieldsFragment;
  onChange: (name: string, value?: PhysicalAddressFieldsFragment) => void;
  name: string;
  defaultUnitType?: string;
  oneLine?: boolean;
  initialSearch?: string;
  loadGoogleApi?: boolean;
}

export default function PhysicalAddressPicker({
  name,
  value,
  onChange,
  defaultUnitType,
  oneLine,
  initialSearch,
  loadGoogleApi
}: PhysicalAddressPickerProps) {
  const [choosing, setChoosing] = useState(false);
  const setAddress = (
    address?: PhysicalAddressFieldsFragment | ResidenceFieldsFragment
  ) => {
    onChange(name, address as PhysicalAddressFieldsFragment);
    setChoosing(false);
  };
  return (
    <div
      className={clsx(
        "mb-4 flex justify-between",
        oneLine ? "items-center" : "items-start"
      )}
    >
      <InputGroup
        className="w-full"
        buttons={
          <>
            {value && (
              <Button onClick={() => setAddress(undefined)}>Remove</Button>
            )}
            <Button onClick={() => setChoosing(true)}>
              {value ? "Change" : "Assign"} Address
            </Button>
          </>
        }
      >
        <div
          className="form-control flex-1 px-[6px]"
          style={{ height: "unset", minHeight: 28 }}
        >
          {value ? (
            <AddressCard physicalAddress={value} oneLine={oneLine} />
          ) : (
            <em>No Address Selected...</em>
          )}
        </div>
      </InputGroup>
      {choosing && (
        <PhysicalAddressForm
          loadGoogleApi={loadGoogleApi}
          defaultUnitType={defaultUnitType}
          initialSearch={initialSearch}
          onClose={() => setChoosing(false)}
          onSave={setAddress}
        />
      )}
    </div>
  );
}
